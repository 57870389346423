import { Typography } from "@material-tailwind/react";

import { cardConfig } from "../config";

export default function Card() {
  const cardList = cardConfig.map((card, index) => {
    return (
      <div
        key={index}
        className="lg:max-w-xs border-2 border-gray-400 rounded-3xl p-3 lg:px-5 mt-5 lg:mt-0"
      >
        <a href={card?.link ? card?.link : "#"}>
          <div className="flex justify-between items-center p-3 lg:pr-5 h-16">
            {card?.image && <img src={card.image} alt={card?.link} className="mr-5" />}
            <Typography className="text-gray-600 text-xl font-bold">
              {card.title}
            </Typography>
          </div>
          <p className="mt-3 px-2 text-md leading-5 text-justify lg:text-start">{card.content}</p>
        </a>
      </div>
    );
  });

  return <div className="lg:flex justify-center gap-20 mt-10">{cardList}</div>;
}
