import { Typography } from "@material-tailwind/react";
import {
  JorgeFonseca,
  JamesZheng,
  DataScientist1,
  DataScientist2,
} from "../assets/images";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Team() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const ImageSlider = (
    <Slider {...settings}>
      <img src={DataScientist1} className="" alt="data scientist1" />
      <img src={DataScientist2} alt="data scientist2" />
    </Slider>
  );

  return (
    <main>
      <section key="Co-founder" className="text-gray-800 p-2">
        <Typography variant="h2" className="text-center">
          Founder
        </Typography>
        <div className="flex justify-center mt-10">
          <img src={JorgeFonseca} alt="cloud" className="sm:max-w-xs" />
        </div>
        <Typography className="text-center text-xl font-bold mt-3">
          Jorge Fonseca
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Our founder is passionate about excellence and has demonstrated
          expertise across various domains. His skill set includes a unique
          blend of critical reasoning, effective communication, creative
          problem-solving, team leadership, analytical abilities, and technical
          expertise.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          In terms of reasoning, he exhibits exceptional critical thinking and
          logical reasoning skills. He excels in analyzing complex situations,
          identifying patterns, and devising innovative solutions by combining
          creativity and analytical thinking to find unconventional solutions to
          intricate problems. He embraces new perspectives and is not afraid to
          think outside the box to achieve breakthrough results.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          In terms of reasoning, he exhibits exceptional critical thinking and
          logical reasoning skills. He excels in analyzing complex situations,
          identifying patterns, and devising innovative solutions by combining
          creativity and analytical thinking to find unconventional solutions to
          intricate problems. He embraces new perspectives and is not afraid to
          think outside the box to achieve breakthrough results.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          As a team leader, he has a natural inclination toward leadership and
          has successfully led diverse teams to achieve outstanding outcomes
          empowering people's collaboration and leveraging the collective
          intelligence of the team to drive success.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          With a deep understanding of technical domains and a strong commitment
          to continuous learning, he provides expert guidance and mentorship in
          areas requiring specialized knowledge. He navigates complex technical
          landscapes and translates intricate concepts into actionable
          strategies that contribute to the success of forward-thinking
          organizations.
        </Typography>
      </section>
      <section key="Collaborators" className="text-gray-800 p-2 mt-20">
        <Typography variant="h2" className="text-center">
          Developers
        </Typography>
        <div className="flex justify-center mt-10">
          <img src={JamesZheng} alt="cloud" className="sm:max-w-xs" />
        </div>
        <Typography className="text-center text-xl font-bold mt-3">
          James Zheng
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Meet our accomplished and innovative Full-Stack Developer with a
          wealth of experience and a proven track record of success developing,
          testing, and deploying major software solutions that leverage API’s
          endpoints, and AI/ML
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          His proficiency extends across a wide range of technologies, including
          GitHub, Jira/Trello, Agile Methodology, Node.js, React.js, Next.js,
          Vue.js, Nuxt.js, Python (Django and Flask), PHP/Laravel, Solidity,
          Web3.js, and HTML/CSS.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Our Full-Stack Developer is a passionate and results-driven
          professional, consistently delivering innovative solutions and
          maximizing efficiency. He thrives in collaborative environments,
          displaying strong adaptability to stay at the forefront of emerging
          technologies and industry trends. His journey in the ever-evolving
          world of web development continues with a focus on creating impactful
          and user-centric applications.
        </Typography>
      </section>
      <section key="Data scientists" className="text-gray-800 p-2 mt-20">
        <div className="hidden sm:flex justify-center gap-20 overflow-x-auto overflow-hidden">
          <img src={DataScientist1} alt="data scientist1" />
          <img src={DataScientist2} alt="data scientist2" />
        </div>
        <div className="sm:hidden">{ImageSlider}</div>
        <Typography className="text-center text-xl font-extrabold mt-3">
          Data Scientists
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          We count on two Data Scientists as members of our Collaborators’ team
          with a proven track record of success in developing business
          strategies and managing projects that have helped organizations
          achieve their growth goals and improve their profits and operational
          efficiency.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          They are proficient in data analysis techniques, including data
          cleaning, exploration, and visualization, to extract insights from
          complex datasets. Also, they have a strong understanding of various
          machine learning algorithms and their applications to build predictive
          models and make data-driven decisions.
        </Typography>
      </section>
    </main>
  );
}
