import { Typography } from "@material-tailwind/react";
import { footerConfig } from "../config";
import LOGO from "../logo.png";
import ContactUs from "./contactus";
import { useState } from "react";

const currentYear = new Date().getFullYear();

export default function Footer() {
  const [open, setOpen] = useState(false);

  return (
    <footer className="relative w-full mt-10">
      <div className="mx-auto w-full max-w-[1320px] px-8 bg-gray-200 pt-6">
        <div className="grid grid-cols-1 justify-between gap-1 md:grid-cols-2">
          <div className="">
            <img src={LOGO} width={"120px"} alt="logo" />
            <Typography
              as="a"
              href="/about"
              color="gray"
              className="py-0.5 sm:py-1.5 font-normal transition-colors hover:text-blue-gray-900 cursor-pointer mt-5"
            >
              About
            </Typography>
            <Typography
              color="gray"
              className="py-0.5 sm:py-1.5 font-normal transition-colors hover:text-blue-gray-900 cursor-pointer"
              onClick={() => setOpen(true)}
            >
              Contact
            </Typography>
            <ContactUs open={open} setOpen={setOpen} />
          </div>
          <div className="sm:flex justify-between sm:gap-4">
            {footerConfig.map(({ title, items }) => (
              <ul className="mt-5 sm:mt-0" key={title}>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-3 font-bold text-md"
                >
                  {title}
                </Typography>
                {items.map((link, index) => (
                  <li key={index}>
                    <Typography
                      as="a"
                      href={link?.link ? link.link : "/"}
                      color="gray"
                      className={`py-0.5 sm:py-1.5 font-normal transition-colors hover:text-blue-gray-900 ${
                        index === 0 && "mt-5"
                      }`}
                    >
                      {link.title}
                    </Typography>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className="mt-20 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-black md:mb-0"
          >
            Copyright &copy; {currentYear}
            {" - "}
            <a href="/">Mutare Group, LLC</a>. All Rights Reserved.
          </Typography>
          <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
          <Typography
              as="a"
              target="_blank"
              href="https://www.instagram.com/mutare.group"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M20.947 8.305a6.53 6.53 0 0 0-.419-2.216 4.61 4.61 0 0 0-2.633-2.633 6.606 6.606 0 0 0-2.186-.42c-.962-.043-1.267-.055-3.709-.055s-2.755 0-3.71.055a6.606 6.606 0 0 0-2.185.42 4.607 4.607 0 0 0-2.633 2.633 6.554 6.554 0 0 0-.419 2.185c-.043.963-.056 1.268-.056 3.71s0 2.754.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.043 1.268.056 3.71.056s2.755 0 3.71-.056a6.59 6.59 0 0 0 2.186-.419 4.615 4.615 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.187.043-.962.056-1.267.056-3.71-.002-2.442-.002-2.752-.058-3.709zm-8.953 8.297c-2.554 0-4.623-2.069-4.623-4.623s2.069-4.623 4.623-4.623a4.623 4.623 0 0 1 0 9.246zm4.807-8.339a1.077 1.077 0 0 1-1.078-1.078 1.077 1.077 0 1 1 2.155 0c0 .596-.482 1.078-1.077 1.078z" />
                  <circle cx="11.994" cy="11.979" r="3.003" />           
              </svg>
            </Typography>            
            <Typography
              as="a"
              target="_blank"
              href="https://www.facebook.com/mutare.group.llc"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
              	<path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.067C0 18.0335 4.33333 22.9944 10 24V15.3333H7V12H10V9.33332C10 6.33332 11.9333 4.66666 14.6667 4.66666C15.5333 4.66666 16.4667 4.79999 17.3333 4.93332V7.99999H15.8C14.3333 7.99999 14 8.73332 14 9.66666V12H17.2L16.6667 15.3333H14V24C19.6667 22.9944 24 18.0335 24 12.067C24 5.43017 18.6 0 12 0C5.4 0 0 5.43017 0 12.067Z"/>
             </svg>
            </Typography>            
            <Typography
              as="a"
              target="_blank"
              href="https://twitter.com/MutareGroup"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M10.053,7.988l5.631,8.024h-1.497L8.566,7.988H10.053z M21,7v10	c0,2.209-1.791,4-4,4H7c-2.209,0-4-1.791-4-4V7c0-2.209,1.791-4,4-4h10C19.209,3,21,4.791,21,7z M17.538,17l-4.186-5.99L16.774,7	h-1.311l-2.704,3.16L10.552,7H6.702l3.941,5.633L6.906,17h1.333l3.001-3.516L13.698,17H17.538z" />
             </svg>
            </Typography>
            <Typography
              as="a"
              href="https://github.com/mutaregroup"
              target="_blank"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                />
              </svg>
            </Typography>
            <Typography
              as="a"
              href="https://www.linkedin.com/company/mutare-group/"
              target="_blank"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="w-5 h-5 text-gray fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
              </svg>
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}
