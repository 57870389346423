import { Typography } from "@material-tailwind/react";
import { useState } from "react";
import CountUp from "react-countup";

import { portfolioConfig } from "../config";
import ContactUs from "./contactus";
import {
  Skills,
  Expertise,
  Monitor,
  WebApp,
  UiUx,
  FullStack,
  AppDev,
  MobileDev,
  Achieve,
  Test,
  Team,
} from "../assets/images";
import { styled } from "styled-components";

const Card = styled.div`
  height: 280px;
  width: 320px;
  border-radius: 10px;
  margin-top: 30px;
  cursor: pointer;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.22);
    transition: 0.7s all ease;
  }
  @media screen and (min-width: 768px) {
    padding: 10px;
    margin-top: 20px;
  }
`;

const Icon = styled.div`
  align-items: center;
  justify-content: center;
  background-color: #dcdcdc;
  height: 55px;
  width: 55px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const Circle = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: #c4c4c4;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const InnerCircle = styled.div`
  background-color: #fff;
  border-radius: 50px;
  padding: 15px;
`;

const CounterCard = styled.div`
  background-color: #cecece;
  height: 180px;
  width: 180px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const FeaturedIcon = styled.div`
  height: 100px;
  width: 100px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 10px;
`;

//@media screen and (min-width: 768px)
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  @media screen and (min-width: 468px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

//flex flex-row items-center justify-evenly mt-20 mb-20
const ContainerTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  flex-direction: column;
  gap: 30px;
  @media screen and (min-width: 468px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
    gap: 30px;
  }
`;

//className="flex flex-col text-center items-center"
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export default function Portfolio() {
  const [open, setOpen] = useState(false);

  const services = portfolioConfig.services.map(
    ({ icon, title, content }, index) => {
      return (
        <div key={index} className="">
          <div className="flex justify-center sm:justify-start">
            {icon}
            <Typography className="ml-5 text-xl">{title}</Typography>
          </div>
          <Typography className="px-2 sm:px-2 text-md leading-6 my-5 text-justify sm:text-start text-gray-600">
            {content}
          </Typography>
        </div>
      );
    }
  );

  return (
    <>
      <section key={"Welcome"}>
        <Typography className="text-center text-gray-900 text-3xl sm:text-4xl font-bold">
          Turn Your Business into a{" "}
          <span className="text-gray-500">Success!</span>
        </Typography>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-10">
          {services}
        </div>
      </section>
      <section className="mt-5 sm:flex" key={"Skills and Expertise"}>
        <img src={Expertise} className="mx-auto" alt="expertise" />
      </section>
      <section className="mt-5" key={"Our Products"}>
        <Typography className="text-gray-800 text-3xl text-center sm:text-4xl mt-20 font-bold">
          Our Skills & Expertise
        </Typography>
        <Typography className="mt-10 text-justify lg:text-start text-gray-600">
          We provide custom software development services, including web and
          mobile apps. Front-end, back-end, and server solutions for desktop
          applications. Hire our expert developers today! Our team works with
          the latest technologies such as React Js, Angular JS, Node JS, Vue JS,
          Express Js, etc. Just ask about the capabilities we can create with
          them.
        </Typography>
        <img src={Skills} className="mx-auto mt-10" alt="skills" />
      </section>
      <div className="flex justify-center text-lg mt-5 items-center">
        <button
          className="p-1 rounded-full text-white bg-gray-700 hover:bg-gray-900 w-28"
          onClick={() => setOpen(true)}
        >
          Contact
        </button>
      </div>
      <ContactUs open={open} setOpen={setOpen} />
      <Typography className="text-center justify-center text-3xl sm:text-4xl text-gray-800 font-bold mt-20">
        We Deliver
      </Typography>
      <Typography className="mt-10 text-justify lg:text-start text-gray-600">
        Mutare Group is a comprehensive IT solutions provider with a team of
        versatile developers. Our solutions will empower our clients to improve their processes. We continuously meet the demands of the
        ever-changing IT industry by adhering to the latest standards. Our
        emphasis on taking action has propelled us to become the top choice for
        web application development services.
      </Typography>
      <div className="lg:flex justify-center gap-20 mt-10">
        <Card>
          <div className="p-4">
            <Icon>
              <img src={Monitor} alt="monitor" width="auto" />
            </Icon>
            <Typography className="font-semibold mb-2 text-gray-800">
              Business web application
            </Typography>
            <Typography className="text-gray-650 text-justify lg:text-start">
              Transform your business with cutting-edge technology. Our web
              application development team delivers powerful digital solutions.
            </Typography>
          </div>
        </Card>
        <Card>
          <div className="p-4">
            <Icon>
              <img src={WebApp} alt="web-app" width="auto" />
            </Icon>
            <Typography className="font-semibold mb-2 text-gray-800">
              Enterprise web application
            </Typography>
            <Typography className="text-gray-600 text-justify lg:text-start">
              Elevate your enterprise with cutting-edge technology. Our enterprise web
              application developers delivers powerful solutions tailored for your business.
            </Typography>
          </div>
        </Card>
      </div>
      <ContainerTwo>
        <InnerContainer>
          <Circle>
            <InnerCircle>
              <img src={UiUx} alt="" width="auto" />
            </InnerCircle>
          </Circle>
          <Typography className="text-center font-bold mt-2 text-gray-800">
            UI UX Design
          </Typography>
        </InnerContainer>
        <InnerContainer>
          <Circle>
            <InnerCircle>
              <img src={AppDev} alt="" width="auto" />
            </InnerCircle>
          </Circle>
          <Typography className="text-center font-bold mt-2 text-gray-800">
            Web Development
          </Typography>
        </InnerContainer>
        <InnerContainer>
          <Circle>
            <InnerCircle>
              <img src={MobileDev} alt="" width="auto" />
            </InnerCircle>
          </Circle>
          <Typography className="text-center font-bold mt-2 text-gray-800">
            Mobile Development
          </Typography>
        </InnerContainer>
        <InnerContainer>
          <Circle>
            <InnerCircle>
              <img src={FullStack} alt="" width="auto" />
            </InnerCircle>
          </Circle>
          <Typography className="text-center font-bold mt-2 text-gray-800">
            Full Stack Engineering
          </Typography>
        </InnerContainer>
      </ContainerTwo>
      <Container>
        <div className="items-center justify-center">
          <Typography className="text-center justify-center text-3xl sm:text-4xl text-gray-800 font-bold mt-20">
            How we deliver
          </Typography>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-0 sm:gap-20 mr-10 ml-10 sm:mr-10 sm:ml-10 mt-10">
            <div className="text-center mb-10">
              <Typography className="font-semibold text-xl mb-2 text-gray-800">
                Availability
              </Typography>
              <Typography className="text-gray-600 text-justify lg:text-start">
                18 hours of coverage Onshore, Nearshore, Offshore Pre-screened
                Resources Faster Team Formation
              </Typography>
            </div>
            <div className="text-center mb-10">
              <Typography className="font-semibold text-xl mb-2 text-gray-800">
                Process
              </Typography>
              <Typography className="text-justify lg:text-start text-gray-600">
                Flexible Engagements Agile Execution Complete Transparency
                Dedicated Teams
              </Typography>
            </div>
            <div className="text-center mb-10">
              <Typography className="font-semibold text-xl mb-2 text-gray-800">
                Technologies
              </Typography>
              <Typography className="text-justify lg:text-start text-gray-600">
                50+ Technologies Legacy to Latest Beta Stage Technologies
              </Typography>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className="mt-[-20px] sm:mt-[-40px]">
          <Typography className="text-center justify-center text-3xl sm:text-4xl text-gray-800 font-bold mb-10 mt-0 sm:mt-10">
            Why Choose Us
          </Typography>
          <Typography className="text-justify lg:text-start mt-5 text-gray-600">
            In today’s competitive business landscape, delivering a personalized
            customer experience has become essential for companies to gain a
            competitive edge.
          </Typography>
          <div className="flex flex-col sm:flex-row items-center justify-evenly mt-10 ml-auto w-full mb-10">
            <div className="w-full">
              <Typography className="text-2xl font-bold mb-20 text-center text-gray-800">
                Our Team
              </Typography>
              <div className="flex flex-row gap-2 sm:gap-10 mt-5 items-center justify-center">
                <div className="flex flex-col gap-2 sm:gap-10 relative bottom-10">
                  <CounterCard>
                    <Typography className="text-2xl font-bold text-gray-800">
                      <CountUp duration={5.5} end={10} />+
                    </Typography>
                    <Typography>Years Of Experience</Typography>
                  </CounterCard>
                  <CounterCard>
                    <Typography className="text-2xl font-bold text-gray-800">
                      <CountUp duration={5.5} end={50} />+
                    </Typography>
                    <Typography>Happy Clients</Typography>
                  </CounterCard>
                </div>
                <div className="flex flex-col gap-2 sm:gap-10">
                  <CounterCard>
                    <Typography className="text-2xl font-bold text-gray-800">
                      <CountUp duration={5.5} end={100} />+
                    </Typography>
                    <Typography>Projects Completed</Typography>
                  </CounterCard>
                  <CounterCard>
                    <Typography className="text-2xl font-bold text-gray-800">
                      <CountUp duration={5.5} end={30} />+
                    </Typography>
                    <Typography>Apps Developed</Typography>
                  </CounterCard>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center gap-3 mt-10 sm:m-auto w-full">
              <div className="flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center gap-5 pt-2 pb-2">
                <div>
                  <FeaturedIcon>
                    <img src={Achieve} alt="" width="auto" />
                  </FeaturedIcon>
                </div>
                <div>
                  <Typography className="text-lg text-center sm:text-left font-bold text-gray-800">
                    Best technical support
                  </Typography>
                  <Typography className="mr-10 ml-10 sm:ml-0 sm:mr-10 text-justify lg:text-start sm:text-left text-gray-600">
                    Our skilled technical support team is available 24/7 to
                    assist with any web app development needs. For the best web
                    application development results, take advantage of our
                    expert technical support team.
                  </Typography>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center gap-5 pt-2 pb-2">
                <div>
                  <FeaturedIcon>
                    <img src={Test} alt="" width="auto" />
                  </FeaturedIcon>
                </div>
                <div>
                  <Typography className="text-lg text-center sm:text-left font-bold text-gray-800">
                    Quality Guaranteed
                  </Typography>
                  <Typography className="mr-10 ml-10 sm:ml-0 sm:mr-10 text-justify lg:text-start sm:text-left text-gray-600">
                    We prioritize quality in all aspects of web app development,
                    from design to testing and deployment. We aim to provide
                    your business with top-of-the-line web applications that
                    inspire confidence and drive success through unmatched
                    reliability and performance.
                  </Typography>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center gap-5 pt-2 pb-2">
                <div>
                  <FeaturedIcon>
                    <img src={Team} alt="" width="auto" />
                  </FeaturedIcon>
                </div>
                <div>
                  <Typography className="text-lg text-center sm:text-left font-bold text-gray-800">
                    Collaborative approach to problem-solving
                  </Typography>
                  <Typography className="mr-10 ml-10 sm:ml-0 sm:mr-10 text-justify lg:text-start sm:text-left text-gray-600">
                    Collaborative problem-solving is a powerful approach to web
                    app development. By working together, teams can leverage
                    their diverse skillsets and perspectives to find creative
                    solutions to complex challenges.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
