import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavHeader from "./components/navbar";
import Content from "./components/content";
import About from "./components/about";
import Footer from "./components/footer";
import NotFound from "./components/notfound";
import Portfolio from "./components/portfolio";
import Cloud from "./components/cloud";
import Support from "./components/support";
import AI from "./components/ai";
import Consulting from "./components/consulting";
import Academy from "./components/academy";
import Team from "./components/team";
import Goods from "./components/goods";

function App() {
  return (
    <>
      <div className="bg-gray-50">
        <NavHeader />
        <div className="max-w-[1320px] mx-auto pt-5 lg:pt-20 px-[4%]">
          <Router>
            <Routes>
              <Route path="/" element={<Content />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/cloud" element={<Cloud />} />
              <Route path="/support" element={<Support />} />
              <Route path="/team" element={<Team />} />
              <Route path="/ai" element={<AI />} />
              <Route path="/academy" element={<Academy />} />
              <Route path="/consulting" element={<Consulting />} />
              <Route path="/goods" element={<Goods />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
