import React from "react";

const SearchInput = () => {
  return (
    <div className="flex items-center border px-4 rounded-[30px]">
      <input
        type="text"
        placeholder="Search"
        className="w-full bg-transparent text-gray-600 focus:border-none py-2 border-none outline-none rounded-lg focus:outline-none focus:border-transparent"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
        />
      </svg>
    </div>
  );
};

export default SearchInput;
