import { Typography } from "@material-tailwind/react";
import { CloudImage } from "../assets/images";
import { useState } from "react";
import ContactUs from "./contactus";

export default function Cloud() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="text-gray-800 p-2">
        <div className="flex justify-center">
          <img src={CloudImage} alt="cloud" className="sm:max-w-3xl" />
        </div>
        <Typography variant="h3" className="mt-10 text-center">
          Cloud
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Our team at Mutare Group is dedicated to ensuring a seamless and
          secure migration to the cloud for our clients. With a wealth of
          experience and a deep understanding of cloud technologies, we are
          confident in our ability to deliver exceptional results and exceed
          expectations.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Whether you require cloud-based communication solutions, data
          management, or other specialized services, Mutare Group is ready to
          assist you on your journey to the cloud. We are excited about this new
          chapter in our company's growth and look forward to providing
          top-notch cloud services to empower businesses in achieving their
          goals.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          If you have any questions or wish to learn more about our cloud
          offerings, please don't hesitate to reach out to us. Thank you for
          your continued support, and we are thrilled to embark on this
          cloud-based journey together!
        </Typography>
        <div className="flex justify-end text-lg mt-4">
          <button
            className="p-1 rounded-full text-white bg-gray-700 hover:bg-gray-900 w-28"
            onClick={() => setOpen(true)}
          >
            Contact
          </button>
        </div>
      </div>
      <ContactUs open={open} setOpen={setOpen} />
    </>
  );
}
