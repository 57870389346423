import {
  Dialog,
  Typography,
  IconButton
} from "@material-tailwind/react";

export function Message({ open, onClose }) {
  return (
    <Dialog
      className="rounded-3xl px-3 py-1"
      open={open}
      size="xs"
      animate={{
        mount: { x: 0, scale: 1, y: 0, rotate: 0 },
        unmount: { x: -1000, scale: 0, y: -1000, rotate: 150 },
      }}
    >
        <div className="flex justify-end"><IconButton
          color="blue-gray"
          size="sm"
          variant="text"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton></div>
        <Typography className="text-center text-3xl font-bold text-gray-600 pt-3 pb-8">
          Thanks
        </Typography>
    </Dialog>
  );
}
