import React from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export default function NavListMenu({ label, submenu, menudescription }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const triggers = {
    onMouseEnter: () => setIsMenuOpen(true),
    onMouseLeave: () => setIsMenuOpen(false),
  };

  const renderItems = submenu.map(({ title, description, link }) => (
    <a href={link ? link : "/"} key={title}>
      <MenuItem className="outline-none">
        <Typography
          variant="h6"
          color="blue-gray"
          className="mb-1 outline-none hover:outline-none"
        >
          {title}
        </Typography>
        <ul className="list-disc px-[1.2rem]">
          {description.map((desc, key) => (
            <li key={key} className="py-1">
              <Typography variant="small" color="gray" className="font-normal">
                {desc}
              </Typography>
            </li>
          ))}
        </ul>
      </MenuItem>
    </a>
  ));

  return (
    <div>
      <Menu open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography
            variant="small"
            className="font-bold text-xl border-outline-none"
          >
            <MenuItem
              {...triggers}
              className="hidden items-center gap-2 text-blue-gray-900 lg:flex lg:rounded-full"
            >
              <span>{label}</span>
              {submenu && submenu.length > 0 && (
                <ChevronDownIcon
                  strokeWidth={2}
                  className={`h-3 w-3 transition-transform ${
                    isMenuOpen ? "rotate-180" : ""
                  }`}
                />
              )}
            </MenuItem>
          </Typography>
        </MenuHandler>
        {submenu && submenu.length > 0 && (
          <MenuList
            {...triggers}
            className="hidden w-[28rem] grid-cols-6 gap-3 overflow-visible lg:grid p-5"
          >
            <Typography className="col-span-12 w-full p-3 border-outline-none">
              {menudescription}
            </Typography>
            <ul className="col-span-12 flex w-full flex-col gap-1 border-outline-none">
              {renderItems}
            </ul>
          </MenuList>
        )}
      </Menu>
      <MenuItem className="flex items-center gap-2 text-blue-gray-900 lg:hidden">
        {label}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul>
    </div>
  );
}
