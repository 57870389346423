import {
  AIWeb,
  EconWeb,
  TigerWeb,
  PropelldWeb,
  KycService,
  KycFaceRecognitionWeb,
  BurgerKingApp,
  BankingApp,
  NewApp,
  RetailerApp,
  CarServiceApp,
  CryptoTradingApp,
  MeetOurTeam,
  JoinTheGoods,
} from "./assets/images";

import {
  HandThumbUpIcon,
  PaintBrushIcon,
  DevicePhoneMobileIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";

export const headerConfig = [
  {
    label: "Solutions",
    menudescription:
      "Mutare Solutions is a division of Mutare Group that provides specialized IT solutions, including applications integration, API design and development, and artificial intelligence.",
    submenu: [
      {
        title: "Applications",
        link: "/portfolio",
        description: [
          "Design, development, and implementation of Applications.",
          "Integrations and APIs.",
        ],
      },
      {
        title: "Artificial Intelligence (AI)",
        link: "/ai",
        description: [
          "Creation of prompt collections of specific topics or subjects for Generative AI.",
        ],
      },
    ],
  },
  {
    label: "Services",
    menudescription:
      "Mutare Services delivers a range of services including cloud, consulting, and support, empowering businesses to optimize their IT infrastructure, leverage cloud computing, and receive expert guidance for their technology needs.",
    submenu: [
      {
        title: "Cloud",
        link: "/cloud",
        description: [
          "Providing a variety of cloud services for from on-demand computing resources and storage.",
        ],
      },
      {
        title: "Consulting",
        link: "/consulting",
        description: [
          "Consulting services offering expert guidance and advice to assist in achieving the business goals.",
        ],
      },
      {
        title: "Support",
        link: "support",
        description: [
          "IT support services providing technical assistance and resolving issues to ensure smooth operation of computer systems and networks.",
        ],
      },
    ],
  },
  {
    label: "Education",
    menudescription:
      "Mutare Education goes beyond traditional IT services by offering comprehensive educational services. Our academy equips individuals and organizations with the necessary knowledge and skills to excel in the dynamic IT landscape.",
    submenu: [
      {
        title: "Academy",
        link: "/academy",
        description: [
          "Enhances employees' learning experience by offering a comprehensive and interactive online platform for training and development purposes.",
        ],
      },
    ],
  },
  {
    searchbar: false,
  },
];

export const contentConfig = {
  slider: ["/slider/1.png", "/slider/2.png", "/slider/3.png"],
};

export const footerConfig = [
  {
    title: "Solutions",
    items: [
      { title: "Applications", link: "/portfolio" },
      { title: "Artificial Intelligence", link: "/ai" },
    ],
  },
  {
    title: "Services",
    items: [
      { title: "Cloud", link: "/cloud" },
      { title: "Consulting", link: "/consulting" },
      { title: "Support", link: "/support" },
    ],
  },
  {
    title: "Education",
    items: [{ title: "Academy", link: "academy" }],
  },
];

export const portfolioConfig = {
  services: [
    {
      icon: (
        <HandThumbUpIcon strokeWidth={2} className="text-gray-600 h-7 w-7" />
      ),
      title: "Proven Team",
      content:
        "By focusing on the needs of our users, we’re able to build products that meet their expectations and exceed their expectations. This approach has helped us attract and retain some of the best talent in the industry",
    },
    {
      icon: (
        <PaintBrushIcon strokeWidth={2} className="text-gray-600 h-7 w-7" />
      ),
      title: "Exquisite Design",
      content:
        "When it comes to design, there is no one-size-fits-all solution. Every client has their own unique needs, and our expert team of designers is up to the challenge of creating a custom solution that meets those needs.",
    },
    {
      icon: (
        <DevicePhoneMobileIcon
          strokeWidth={2}
          className="text-gray-600 h-7 w-7"
        />
      ),
      title: "Innovative & Strategic",
      content:
        "At Mutare we believe that great ideas can come from anywhere. That’s why we’ve worked to create an environment conducive to creativity and collaboration. We focus on transparency and respect, valuing every team member’s contribution.",
    },
    {
      icon: (
        <RectangleGroupIcon strokeWidth={2} className="text-gray-600 h-7 w-7" />
      ),
      title: "Technical Partner",
      content:
        "We understand that effective communication is essential to the success of any business partnership. That's why we take the time to really listen to your needs and concerns. This is why a dedicated account executive is with you from start to finish.",
    },
  ],
  skills: {
    title: "Our Skills & Expertise",
    content:
      "Provides custom software development services, including web and mobile apps. Front-end, back-end and server solutions for desktop applications. Hire our expert developers today! Our team works with the latest technologies such as AngularJS, EmberJS, NodeJS, ReactJs etc. Just ask our capabilities we are certain of them.",
    skills: [
      {
        title: "Web & Mobile",
        percent: "98%",
      },
      {
        title: "VR/AR/Games",
        percent: "87%",
      },
      {
        title: "Crypto/NFT/dApp",
        percent: "94%",
      },
    ],
  },
  portfolios: [
    {
      title: "frontend, web",
      image: KycFaceRecognitionWeb,
    },
    {
      title: "frontend, web",
      image: EconWeb,
    },
    {
      title: "frontend, web",
      image: PropelldWeb,
    },
    {
      title: "backend, app",
      image: KycService,
    },

    {
      title: "gaming, frontend",
      image: AIWeb,
    },
    {
      title: "gaming",
      image: TigerWeb,
    },
    {
      title: "backend",
      image: BurgerKingApp,
    },
    {
      title: "app, backend",
      image: BankingApp,
    },
    {
      title: "app",
      image: NewApp,
    },
    {
      title: "app, backend",
      image: RetailerApp,
    },
    {
      title: "fronend, app",
      image: CarServiceApp,
    },
    {
      title: "frontend, app",
      image: CryptoTradingApp,
    },
  ],
};

export const cardConfig = [
  {
    card: "Meet our team",
    image: MeetOurTeam,
    title: "Meet our team",
    content:
      "At Mutare Group, we take pride in our exceptional team of high-skilled professionals who are passionate about delivering top-notch IT solutions and services",
    link: "/team",
  },
  {
    card: "Join the goods",
    image: JoinTheGoods,
    title: "Join the goods",
    content:
      "Are you ready to take your IT expertise to new heights? Mutare is on the lookout for exceptional individuals like you join our team of collaborators",
    link: "/goods",
  },
];
