import { Typography } from "@material-tailwind/react";
import { contentConfig } from "../config";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Card from "./cards";

function ImageSlider() {
  return (
    <Carousel infiniteLoop={true} autoPlay showThumbs={false} interval={10000}>
      {contentConfig.slider.map((slide, key) => (
        <figure className="relative h-full w-full w-fds" key={key}>
          <img
            className="h-full w-full rounded-xl"
            src={slide}
            alt="natureimage"
          />
        </figure>
      ))}
    </Carousel>
  );
}

function Content() {
  return (
    <main>
      <section className="grid lg:grid-cols-2 ">
        <div className="flex justify-center items-center lg:px-8 ">
          <Typography className="p-5 lg:p-5 lg:pr-16 text-md text-justify lg:text-left lg:text-xl lg:leading-8 tracking-widest">
            Mutare Group is an IT company providing a comprehensive range of
            innovative IT services, such as system integration, cybersecurity,
            data analytics, and IT consulting, to help businesses drive growth
            and efficiency in the digital era.
          </Typography>
        </div>
        <div className="mt-5 lg:mt-3">
          <ImageSlider />
        </div>
      </section>
      <Card />
    </main>
  );
}

export default Content;
