import React from "react";
import { Collapse } from "react-collapse";
import { BsChevronDown, BsArrowUp } from "react-icons/bs";
import { Typography } from "@material-tailwind/react";

const AccordionItem = ({ open, toggle, title, desc }) => {
  return (
    <div className="pt-[15px] rounded-lg">
      <div
        className="bg-[#fff] shadow-lg py-[18px] sm:px-[50px] px-[20px] flex justify-between cursor-pointer rounded-lg"
        onClick={toggle}
      >
        <div className="flex gap-10 items-center">
          <p
            className="text-[14px] sm:text-[22px] text-gray-800 text-left font-semibold"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            {title}
          </p>
        </div>
        <div className="text-[16px] sm:text-[30px] text-gray-600 max-md:ml-2 max-md:flex max-md:items-center max-md:text-[24px] max-sm:text-[14px]">
          {open ? <BsArrowUp /> : <BsChevronDown />}
        </div>
      </div>
      <Collapse isOpened={open}>
        <Typography className="pt-[10px] px-[50px] pb-[20px] text-gray-600 max-md:text-[14px]">
          {desc}
        </Typography>
      </Collapse>
    </div>
  );
};

export default AccordionItem;
