import { Typography } from "@material-tailwind/react";

export default function About() {
  return (
    <div className="text-gray-800">
      <Typography variant="h3" className="mt-[-20px] text-center">
        About Mutare Group
      </Typography>
      <Typography className="mt-2">
        Welcome to Mutare Group, an IT services company dedicated to providing
        innovative IT solutions that propel businesses toward growth and
        efficiency in the digital era. With a deep understanding of the evolving
        business landscape and cutting-edge technologies, we offer a
        comprehensive range of services in system integration, cybersecurity,
        data analytics, and expert IT consulting tailored to our client's unique
        needs.
      </Typography>
      <Typography variant="h6" className="mt-6">
        Mutare Solutions: Tailored IT Solutions for Your Needs
      </Typography>
      <Typography>
        Mutare Solutions offers customized IT solutions designed to cater to
        your specific requirements. Whether you need applications integration,
        API design and development, or harnessing the power of artificial
        intelligence (AI), Mutare Solutions is dedicated to helping businesses
        thrive in the ever-evolving digital realm.
      </Typography>
      <Typography variant="h6" className="mt-6">
        Mutare Services: Your Trusted IT Partner
      </Typography>
      <Typography>
        Mutare Services is committed to delivering diverse top-notch services to
        empower businesses to optimize their IT infrastructure. Through our
        consulting expertise, cloud solutions, and unwavering support, we assist
        organizations in leveraging the full potential of cloud computing while
        providing expert guidance to meet their unique technology needs.
      </Typography>
      <Typography variant="h6" className="mt-6">
        Mutare Education: Empowering Success through Knowledge
      </Typography>
      <Typography>
        Mutare Education goes beyond traditional IT services by offering
        comprehensive educational services. Our academy equips individuals and
        organizations with the necessary knowledge and skills to excel in the
        dynamic IT landscape. Our tailored programs foster continuous learning,
        ensuring you stay ahead in the ever-changing world of technology.
      </Typography>
      <br />
      <Typography>
        Join hands with Mutare Group and unlock the true potential of your
        business in the digital era. Contact us today to embark on a
        transformative journey toward technological excellence.
      </Typography>
    </div>
  );
}
