import { Typography } from "@material-tailwind/react";
import { AcademyImage } from "../assets/images";
import { useState } from "react";
import ContactUs from "./contactus";

export default function Academy() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="text-gray-800 p-2">
        <div className="flex justify-center">
          <img src={AcademyImage} alt="Academy" className="sm:max-w-3xl" />
        </div>
        <Typography variant="h3" className="mt-10 text-center">
          Academy
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          At Mutare Group, we specialize in creating and implementing
          comprehensive training solutions. Our expertise extends to developing
          training materials and courses tailored to meet your specific needs.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Our Academy offers a diverse collection of training courses covering
          various IT topics and subjects, providing a centralized platform to
          access all your training resources. Our team can create and deliver
          top-notch training experiences.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Additionally, we can manage certification processes, ensuring that
          your team members receive the recognition they deserve for their
          newfound skills and knowledge. Partner with us for a seamless and
          effective training journey that empowers your workforce and enhances
          your organization's capabilities.
        </Typography>
        <div className="flex justify-end text-lg mt-4">
          <button
            className="p-1 rounded-full text-white bg-gray-700 hover:bg-gray-900 w-28"
            onClick={() => setOpen(true)}
          >
            Contact
          </button>
        </div>
      </div>
      <ContactUs open={open} setOpen={setOpen} />
    </>
  );
}
