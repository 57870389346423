import { Typography } from "@material-tailwind/react";
import { ConsultingImage } from "../assets/images";
import { useState } from "react";
import ContactUs from "./contactus";

export default function Consulting() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="text-gray-800 p-2">
        <div className="flex justify-center">
          <img
            src={ConsultingImage}
            alt="Consulting"
            className="sm:max-w-3xl"
          />
        </div>
        <Typography variant="h3" className="mt-10 text-center">
          Consulting
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Maximize your business's IT potential with our comprehensive IT
          consulting services. Our team of experts is equipped to analyze and
          optimize your processes, identifying areas for improvement and
          streamlining operations for enhanced efficiency.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          With a focus on innovative designs and best practices, we can provide
          solutions tailored to your unique needs, whether it's software
          development, system integration, or network infrastructure.
        </Typography>
        <div className="flex justify-end text-lg mt-4">
          <button
            className="p-1 rounded-full text-white bg-gray-700 hover:bg-gray-900 w-28"
            onClick={() => setOpen(true)}
          >
            Contact
          </button>
        </div>
      </div>
      <ContactUs open={open} setOpen={setOpen} />
    </>
  );
}
