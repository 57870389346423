import { Typography } from "@material-tailwind/react";
import { useState } from "react";
import ContactUs from "./contactus";

export default function Goods() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="text-gray-800 p-2">
        <Typography variant="h3" className="text-center">
          Join Our Team at Mutare Group IT Company - Exciting Opportunities Await!
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          We are thrilled to extend an invitation to join our dynamic team at Mutare. As a player in the IT domain, we are constantly seeking highly skilled and talented collaborators like you to help drive innovation and excellence in our projects.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          At Mutare Group, we believe that our success lies in the collective expertise and passion of our team members. As we continue to grow and expand our horizons, we are excited to welcome new individuals who share our vision and values.
        </Typography>
        <Typography variant="h6" className="mt-4 text-lg text-justify sm:text-start">
          Why Join Mutare Group?
        </Typography>
        <Typography className="mt-4 text-lg">As a member of our team, you'll have the opportunity to work on projects that push the boundaries of technology in a great place to work:</Typography>
        <ul className="ml-10 mt-4 text-justify lg:text-start" style={{ listStyleType: 'circle' }}>
          <li>
            <Typography><strong>Cutting-Edge Projects</strong></Typography>
          </li>
          <li>
            <Typography><strong>Collaborative Work Environment</strong></Typography>
          </li>
          <li>
            <Typography><strong>Growth and Development</strong></Typography>
          </li>
          <li>
            <Typography><strong>Impactful Solutions</strong></Typography>
          </li>
          <li>
            <Typography><strong>Inclusive and Diverse Workplace</strong></Typography>
          </li>
        </ul>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          If you are interested in becoming a part of the Mutare Group team of collaborators, we encourage you to click on the <strong>Contact</strong> button below and let us know about you.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">Join us in shaping the future of technology and making a difference in the world. Together, we can achieve great things.</Typography>
        <div className="flex justify-end text-lg mt-4">
          <button
            className="p-1 rounded-full text-white bg-gray-700 hover:bg-gray-900 w-28"
            onClick={() => setOpen(true)}
          >
            Contact
          </button>
        </div>
      </div>
      <ContactUs open={open} setOpen={setOpen} />
    </>
  );
}
