import { Typography } from "@material-tailwind/react";
import {
  AIImage,
  AIM,
  Consulting,
  DS,
  Gif,
  ML,
  NL,
  Setting,
} from "../assets/images";
import { useState } from "react";
import ContactUs from "./contactus";
import { styled } from "styled-components";
import { faqs } from "../context/FaqData";
import AccordionItem from "./AccordionItem";

const Card = styled.div`
  height: 340px;
  width: 340px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.12);
  &:hover {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.44);
    transition: 0.7s all ease;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Icon = styled.div`
  align-items: center;
  justify-content: center;
  background-color: #c4c4c4;
  height: 55px;
  width: 55px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const Button = styled.div`
  border-radius: 20px;
  height: 40px;
  width: 220px;
  padding: 10px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`;

const Image = styled.img`
  filter: grayscale(100%);
`;

export default function AI() {
  const [open, setOpen] = useState(false);
  const [accOpen, setAccOpen] = useState(false);

  const toggle = (index) => {
    if (accOpen === index) {
      return setAccOpen(null);
    }

    setAccOpen(index);
  };

  return (
    <>
      <div className="text-gray-800 p-2">
        <div className="flex justify-center">
          <img src={AIImage} alt="ai" className="sm:max-w-3xl" />
        </div>
        <Typography variant="h3" className="mt-10 text-center">
          Artificial Intelligence
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Unlock the potential of your applications with our AI services. We
          offer seamless integration and utilization of cutting-edge artificial
          intelligence technologies to enhance your applications' capabilities.
          Leverage the power of AI to deliver personalized user experiences,
          automate repetitive tasks, and gain valuable insights from your data.
          Let us help you take your applications to the next level with our AI
          expertise.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Creating prompts and specific subject prompt collections is essential
          for maximizing the effectiveness and efficiency of language models,
          such as GPT-3, and generating relevant and accurate responses. The
          specific subject prompt collection guides the language model to offer
          precise instructions for baking a chocolate cake, ensuring relevancy
          and accuracy.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          In summary, creating prompts and specific subject prompt collections
          is vital for fine-tuning language models and obtaining high-quality
          responses that align with the desired output and context. By
          leveraging prompts effectively, developers can enhance the model's
          understanding, tailor responses to their application's requirements,
          and improve accuracy and relevance, ultimately enhancing the user
          experience and achieving better results in various AI-powered
          applications.
        </Typography>
        <div className="flex justify-center text-lg mt-5 items-center">
          <button
            className="p-1 rounded-full text-white bg-gray-700 hover:bg-gray-900 w-28"
            onClick={() => setOpen(true)}
          >
            Contact
          </button>
        </div>
      </div>
      <ContactUs open={open} setOpen={setOpen} />
      <Typography
        variant="h3"
        className="mt-20 text-center text-gray-800 mb-[-50px]"
      >
        Our Offerings
      </Typography>
      <div className="flex flex-col sm:flex-row items-center justify-center m-10 gap-5 mt-20">
        <Card>
          <div className="p-5">
            <Icon>
              <img src={Consulting} alt="" width="auto" />
            </Icon>
            <Typography className="font-bold mb-2 text-gray-800">
              AI Consulting & Development
            </Typography>
            <Typography className="text-justify sm:text-start">
              Our AI Consulting and Development Services help you to accelerate
              business growth, integrate AI into your existing technology stack,
              and build advanced AI solutions to optimize business operations.
            </Typography>
          </div>
        </Card>
        <Card>
          <div className="p-5">
            <Icon>
              <img src={ML} alt="" width="auto" />
            </Icon>
            <Typography className="font-bold mb-2 text-gray-800">
              Machine Learning Development
            </Typography>
            <Typography className="text-justify sm:text-start">
              We provide Machine Learning development services for advanced data
              analytics and business process automation. Decisions can be made
              faster with our solutions by deploying machine learning models.
            </Typography>
          </div>
        </Card>
        <Card>
          <div className="p-5">
            <Icon>
              <img src={AIM} alt="" width="auto" />
            </Icon>
            <Typography className="font-bold mb-2 text-gray-800">
              AI-Powered Mobile and Web Applications
            </Typography>
            <Typography className="text-justify sm:text-start">
              Our AI-based mobile and web applications provide a future-ready
              solution for improved user experience. By leveraging AI
              functionalities, we offer personalized browsing and a better
              understanding of user behavior.
            </Typography>
          </div>
        </Card>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center m-10 gap-5 mb-20">
        <Card>
          <div className="p-5">
            <Icon>
              <img src={NL} alt="" width="auto" />
            </Icon>
            <Typography className="font-bold mb-2 text-gray-800">
              Natural Language Processing
            </Typography>
            <Typography className="text-justify sm:text-start">
              Our NLP services enable the extraction of meaning from large data
              sets. We build next-generation digital assistants that understand
              multiple parts of speech, contextual nuances, sentiments, and
              languages for personalized customer service.
            </Typography>
          </div>
        </Card>
        <Card>
          <div className="p-5">
            <Icon>
              <img src={Setting} alt="" width="auto" />
            </Icon>
            <Typography className="font-bold mb-2 text-gray-800">
              Data Support for AI/ML
            </Typography>
            <Typography className="text-justify sm:text-start">
              We provide accurate data annotation services for text, images, and
              videos to train AI/ML models for maximum business efficiency. Our
              certified experts ensure confidentiality, precision, and accuracy
              through AI & ML centers of excellence
            </Typography>
          </div>
        </Card>
        <Card>
          <div className="p-5">
            <Icon>
              <img src={DS} alt="" width="auto" />
            </Icon>
            <Typography className="font-bold mb-2 text-gray-800">
              AI Solution Support and Optimization
            </Typography>
            <Typography className="text-justify sm:text-start">
              Our AI support services offer 24/7 support, monitoring, and
              optimization for existing and new AI solutions. We continually add
              new data resources to improve insights and accuracy and develop
              ML-based models to meet evolving business needs.
            </Typography>
          </div>
        </Card>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center gap-10 m-5">
        <div className="order-2 sm:order-1">
          <Typography className="text-2xl font-bold mt-20 mb-5 text-gray-800">
            Innovative AI/ML Solutions
          </Typography>
          <Typography className="text-justify sm:text-start">
            Using artificial intelligence-powered applications, companies are
            automating their traditional business practices to improve their
            processes. Our AI experts possess the skills and knowledge required
            to apply AI and Machine Learning algorithms to minimize operational
            expenses, maximize business earnings, and enhance customer
            satisfaction. To quickly and efficiently run your business, we will
            assist you in harnessing the power of artificial intelligence.
          </Typography>
          <Button className="hover:bg-gray-900 bg-gray-700 text-white">
            <button onClick={() => setOpen(true)}>
              Get A Free Consultation
            </button>
          </Button>
          <ContactUs open={open} setOpen={setOpen} />
        </div>
        <div className="order-1 sm:order-2">
          <Image src={Gif} alt="" width="1200px" />
        </div>
      </div>
      <div className="mt-10 mb-10">
        <Typography className="text-center justify-center text-3xl text-gray-800 font-bold mt-20">
          Frequently Asked Questions
        </Typography>
        <div className="mt-10 items-center justify-center">
          {faqs.map((faq, index) => {
            return (
              <AccordionItem
                key={index}
                open={index === accOpen}
                title={faq.title}
                desc={faq.desc}
                no={faq.no}
                toggle={() => toggle(index)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
