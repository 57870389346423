import { Typography } from "@material-tailwind/react";
import { SupportImage } from "../assets/images";
import { useState } from "react";
import ContactUs from "./contactus";

export default function Support() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="text-gray-800 p-2">
        <div className="flex justify-center">
          <img src={SupportImage} alt="support" className="sm:max-w-3xl" />
        </div>
        <Typography variant="h3" className="mt-10 text-center">
          Support
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Mutare Group takes pride in offering a comprehensive range of support
          services designed to cater to the specific needs of our valued
          clients.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          Our personalized approach ensures that every customer receives
          tailored solutions, making them feel prioritized and understood.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          With a white-glove service mindset, our dedicated team goes above and
          beyond to deliver exceptional assistance, handling any challenges that
          may arise with utmost care and attention to detail. Leveraging
          cutting-edge automation tools, we streamline processes, expedite issue
          resolution, and enhance overall efficiency, leaving our clients with
          the confidence that we have everything under control.
        </Typography>
        <Typography className="mt-4 text-lg text-justify sm:text-start">
          When you partner with Mutare Group, you can trust that we will
          proactively identify and fix any issues that come your way, allowing
          you to focus on your core business while we take care of the rest.
        </Typography>
        <div className="flex justify-end text-lg mt-4">
          <button className="p-1 rounded-full text-white bg-gray-700 hover:bg-gray-900 w-28" onClick={() => setOpen(true)}>Contact</button>
        </div>
      </div>
      <ContactUs open={open} setOpen={setOpen} />
    </>
  );
}
