import { useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import emailjs from "@emailjs/browser";
import { Message } from "./message";
import { useEffect } from "react";

const getPageTitle = (pagename) => {
  switch (pagename) {
    case '': return "Home";
    case 'portfolio': return "Application";
    case 'ai': return "AI";
    case 'cloud': return "Cloud";
    case 'consulting': return "Consulting";
    case 'support': return "Support";
    case 'academy': return "Academy";
    case 'goods': return "Goods";
    case 'team': return "Team";
    default: break;
  }
}

export default function ContactUs({ open, setOpen, title }) {
  const handleOpen = () => setOpen(!open);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const messageRef = useRef(null);

  const [isOpenMessage, setOpenMessage] = useState(false);

  useEffect(() => {
    if (window) {
      const urls = window.location.href.split('/');
      setSubject(`Message From ${getPageTitle(urls[urls.length - 1])} Page`);
    }
  }, []);

  const initErrors = () =>
    setTimeout(() => {
      setErrors({});
    }, 3000);

  const handleSendMessage = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (name.trim() === "") {
      validationErrors.name = "Your Name is required";
    }
    if (email.trim() === "") {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Email is invalid";
    }
    if (message.trim() === "") {
      validationErrors.message = "Message is required";
    }
    if (phone.trim() === "") {
      validationErrors.phone = "Phone is required";
    }

    setErrors(validationErrors);
    initErrors();

    if (Object.keys(validationErrors).length === 0) {
      const params = {
        to_name: "Mutare Group",
        name: name,
        email: email,
        subject: subject,
        message: message,
        phone: phone,
      };

      emailjs
        .send(
          "service_m6iolo6",
          "template_3w2xjfh",
          params,
          "4iBP0MVVFmZjM-M6D"
        )
        .then(
          (response) => {
            setOpenMessage(true);
            handleOpen();
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        handler={handleOpen}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <div className="flex items-center justify-between">
          <DialogHeader>Contact Us</DialogHeader>
          <XMarkIcon
            className="mr-3 h-5 w-5 cursor-pointer"
            onClick={handleOpen}
          />
        </div>
        <DialogBody>
          <Input
            required
            label="Name"
            color="gray"
            onChange={(e) => setName(e.target.value)}
            error={errors.name && true}
          />
          {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
          <div className="sm:flex mt-3 gap-3">
            <div className="sm:w-1/2">
              <Input
                required
                label="Email"
                color="gray"
                onChange={(e) => setEmail(e.target.value)}
                error={errors.email && true}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <div className="sm:w-1/2 mt-3 sm:mt-0">
              <Input
                required
                label="Phone"
                color="gray"
                onChange={(e) => setPhone(e.target.value)}
                error={errors.phone && true}
              />
              {errors.phone && (
                <p className="text-red-500 text-sm">{errors.phone}</p>
              )}
            </div>
          </div>
          {/* <div className="mt-3">
            <Input
              label="Subject"
              color="gray"
              disabled
              // onChange={(e) => setSubject(e.target.value)}
              error={errors.subject && true}
              value={subject}
            />
            {errors.subject && (
              <p className="text-red-500 text-sm">{errors.subject}</p>
            )}
          </div> */}
          <div className="mt-3">
            <Textarea
              id="message"
              required
              label={<p>Message <span style={{color: 'red'}}>*</span></p>}
              color="gray"
              onChange={(e) => setMessage(e.target.value)}
              error={errors.message && true}
              ref={messageRef}
            />
            {errors.message && (
              <p className="text-red-500 text-sm">{errors.message}</p>
            )}
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <div className="flex flex-col items-center gap-4 m-auto">
            <Button
              size="lg"
              className="group relative flex items-center gap-3 overflow-hidden pr-[72px] bg-gray-800 transition-colors hover:bg-gray-700"
              onClick={handleSendMessage}
            >
              Send Message
              <span className="absolute right-0 grid h-full w-12 place-items-center bg-gray-800 transition-colors group-hover:bg-gray-700">
                <img
                  src="/logos/send-message.png"
                  alt="send-message"
                  className="h-5 w-5"
                />
              </span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
      {isOpenMessage && (
        <Message open={isOpenMessage} onClose={() => setOpenMessage(false)} />
      )}
    </>
  );
}
